<template>
  <div>
    <b-button
      v-if="!id"
      variant="primary"
      @click="informationModal"
      :disabled="disabled.edit"
      >Add</b-button
    >
    <b-button
      v-if="status == 0"
      variant="danger"
      @click="deleteModal"
      :disabled="disabled.delete"
      class="mr-2"
      >Delete</b-button
    >
    <b-button
      v-if="status == 0 || status == 1"
      variant="primary"
      @click="informationModal"
      :disabled="disabled.edit"
      class="mr-2"
      >Edit</b-button
    >
    <b-button
      v-if="status == 2"
      variant="primary"
      @click="informationModal"
      :disabled="disabled.view"
      class="mr-2"
      >View</b-button
    >
    <b-modal
      v-model="showModal.information"
      centered
      no-close-on-backdrop
      scrollable
      :title="id ? 'Edit' : 'Add'"
      id="void_target"
      ref="modal"
      size="lg"
    >
      <b-overlay :show="loading">
        <b-tabs v-model="tabs" content-class="mt-3 mb-10" lazy>
          <b-tab title="Information">
            <b-row>
              <b-col md="12" class="mt-1 mb-3">
                <h5 class="subtitle">Invoice</h5>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="invoice_no"
                  label-for="invoice_no"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Invoice No."
                >
                  <b-form-input
                    v-model="save.invoice_no"
                    type="text"
                    :placeholder="$t('page.please_choose_input')"
                    :disabled="status == 2"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="invoice_date"
                  label-for="invoice_date"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Invoice Date"
                >
                  <b-input-group>
                    <template v-slot:append>
                      <div
                        v-if="
                          save.invoice_date != null && save.invoice_date != ''
                        "
                        class="datepicker-x"
                        @click="initialTime(save, 'invoice_date')"
                      >
                        <b-icon-x font-scale="1.45"></b-icon-x>
                      </div>
                      <b-input-group-text>
                        <b-icon-calendar2-date></b-icon-calendar2-date>
                      </b-input-group-text>
                    </template>
                    <b-form-datepicker
                      v-model="save.invoice_date"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                      }"
                      :hide-header="true"
                      :disabled="status == 2"
                      placeholder="No date selected"
                      locale="de"
                    ></b-form-datepicker>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="refundable"
                  label-for="refundable"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Tax Refund"
                >
                  <sv-select
                    v-model="save.refundable"
                    :placeholder="$t('page.please_choose')"
                    :options="options.refundable"
                    :reduce="(options) => options.value"
                    :selectable="(options) => !options.disabled"
                    :disabled="status == 2"
                    label="label"
                  >
                  </sv-select>
                </b-form-group>
              </b-col>
              <b-col md="4" class="my-1">
                <b-form-group
                  class="invoice_total_amount required"
                  label-for="invoice_total_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Total Amount"
                >
                  <b-input-group>
                    <template v-slot:append>
                      <b-input-group-text>
                        <strong>€</strong>
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      v-model="save.invoice_total_amount"
                      type="text"
                      :placeholder="$t('page.please_choose_input')"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="mt-5 mb-3">
                <h5 class="subtitle">Select Purchase</h5>
              </b-col>
              <b-col md="12" class="my-1">
                <b-form-group
                  class="purchase_overview_id required"
                  label-for="purchase_overview_id"
                  label-cols-sm="2"
                  label-cols-lg="2"
                  label-align-sm="right"
                  label-size="sm"
                  label="Purchase"
                >
                  <sv-select
                    @search="onSearch"
                    class="select-mh-9"
                    v-model="save.purchase_overview_id"
                    placeholder="Purchase ID、Order ID or Product Name"
                    :options="options.purchase_overview_id"
                    :disabled="status == 2"
                    multiple
                  >
                    <!-- <template slot="option" slot-scope="option">
                      {{ option }} 1
                    </template> -->
                    <template slot="selected-option" slot-scope="option">
                      {{ option.system_id }}
                    </template>
                  </sv-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="!isHasFile">
              <b-col md="12" class="mt-5 mb-3">
                <h5 class="subtitle">Upload File</h5>
              </b-col>
              <b-col md="12" v-if="save.file_name != ''">
                <div class="action">
                  <p class="file_name mr-2">{{ save.file_name }}</p>
                  <!-- <div class="action-tools">
                    <van-uploader
                      class="action-btn mr-2"
                      :after-read="uploadFile"
                      :before-delete="uploadDelete"
                      accept=".pdf"
                      :max-count="1"
                      ref="vanUploader"
                      :disabled="disabled.upload"
                    >
                      <b-button variant="primary" class="btn upload-check">
                        Change
                      </b-button>
                    </van-uploader>
                    <b-button
                      variant="danger"
                      class="btn upload-delete"
                      :disabled="disabled.upload"
                      @click="uploadDelete"
                    >
                      Delete
                    </b-button>
                  </div> -->
                </div>
              </b-col>
              <b-col md="3" class="my-1" v-if="save.file_name == ''">
                <b-form-group
                  class="search-flex"
                  label-cols-sm="4"
                  label-cols-lg="3"
                >
                  <van-uploader
                    class="storage_file_name action-btn"
                    :after-read="uploadFile"
                    :before-delete="uploadDelete"
                    accept=".pdf"
                    :max-count="1"
                    preview-size="0"
                    ref="vanUploader"
                    :disabled="disabled.upload"
                  >
                    <b-button variant="primary"> Upload </b-button>
                  </van-uploader>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Logs" v-if="id">
            <SimpleTable
              :items="items"
              :fields="fields"
              :tableId="'logs'"
              style="margin-top: 20px; min-height: 200px"
            ></SimpleTable>
          </b-tab>
        </b-tabs>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="submit()"
          :disabled="disabled.submit"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      v-model="showModal.delete"
      centered
      no-close-on-backdrop
      scrollable
      title="Please Confirm"
      id="please_confirm"
      ref="modal"
    >
      <b-overlay :show="loading2">
        Are you sure to delete this invoice?
      </b-overlay>

      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="primary"
          @click="confirm()"
          :disabled="disabled.confirm"
        >
          Confirm
        </b-button>
        <b-button variant="secondary" @click="close()">
          {{ $t('page.cancel') }}
        </b-button>
      </template></b-modal
    >
  </div>
</template>
<script>
import { mapState } from 'vuex';
import _ from 'lodash';
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import SimpleTable from '@/components/Table/Simple.vue';
// import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
export default {
  name: 'uploadManagement',
  data: function () {
    return {
      tabs: 0,
      showModal: {
        information: false,
        delete: false
      },
      disabled: {
        delete: false,
        edit: false,
        view: false,
        submit: false,
        confirm: false,
        upload: false
      },
      save: {
        invoice_no: null,
        refundable: null,
        invoice_date: null,
        invoice_total_amount: null,
        purchase_overview_id: [],
        storage_file_name: '',
        file_name: ''
      },
      saveDefault: {
        invoice_no: null,
        refundable: null,
        invoice_date: null,
        invoice_total_amount: null,
        purchase_overview_id: [],
        storage_file_name: '',
        file_name: ''
      },
      loading: false,
      loading2: false,
      isHasFile: false,
      items: [],
      fields: [
        {
          key: 'created_at',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'log_action',
          label: 'Action'
        },
        {
          key: 'detail',
          label: 'Details'
        }
      ],

      options: {
        refundable: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 2 },
          { label: 'Uncertain', value: 3 }
        ],
        purchase_overview_id: []
      }
    };
  },
  components: { SimpleTable },
  props: ['id', 'initFn', 'status'],
  methods: {
    //   删除
    initialTime(obj, name) {
      obj[name] = null;
    },
    onSearch: function (search, loading) {
      if (search != null && search != '') {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // 做放抖动处理
    search: _.debounce((loading, search, vm) => {
      apiBus.uploadInvoice
        .purchaseInvoiceSupplierList({ keyWord: search })
        .then((data) => {
          // console.log(data);
          vm.options.purchase_overview_id = data.data.data;
          loading(false);
        })
        .catch((error) => {
          console.log(error);
          vm.options.purchase_overview_id = [];
          loading(false);
        });
    }, 350),

    informationModal() {
      this.showModal.information = true;
      if (!this.id) return;
      this.loading = true;
      this.disabled.submit = true;
      apiBus.uploadInvoice
        .getUploadPurchaseInvoiceFileInfo({ id: this.id })
        .then((data) => {
          this.loading = false;
          this.disabled.submit = false;
          let {
            invoice_date,
            invoice_no,
            // purchase_overview_id,
            refundable,
            select,
            file_name,
            storage_file_name,
            invoice_total_amount
          } = data.data.data;
          this.save.invoice_date = invoice_date;
          this.save.invoice_no = invoice_no;
          this.save.invoice_total_amount = invoice_total_amount;
          this.save.purchase_overview_id = select;
          this.save.refundable = refundable == 0 ? null : refundable;
          this.options.purchase_overview_id = select ?? [];
          this.save.id = null;
          this.save.file_name = file_name;
          this.save.storage_file_name = storage_file_name;
          if (file_name != '') {
            this.isHasFile = true;
          }
        })
        .catch((error) => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      apiBus.uploadInvoice
        .getPurchaseInvoiceFileLog({ id: this.id })
        .then((data) => {
          this.items = data.data.data;
        })
        .catch((error) => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    uploadFile(e) {
      this.disabled.upload = true;
      let imgFile = new FormData();
      imgFile.append('file', e.file);
      apiBus.uploadInvoice
        .uploadPurchaseSingleInvoiceFile(imgFile)
        .then((data) => {
          console.log(data);
          this.save.file_name = data.data.data.file_name;
          this.save.storage_file_name = data.data.data.path;
          this.disabled.upload = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          this.disabled.upload = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    uploadDelete() {
      this.disabled.upload = true;
      apiBus.uploadInvoice
        .deletePurchaseSingleInvoiceFile({
          path: this.save.storage_file_name,
          file_name: this.save.file_name
        })
        .then((data) => {
          console.log(data);
          this.save.storage_file_name = '';
          this.save.file_name = '';
          this.disabled.upload = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          this.disabled.upload = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
      return false;
    },
    submit() {
      if (this.save.purchase_overview_id.length == 0) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      if (
        this.save.invoice_total_amount === '' ||
        this.save.invoice_total_amount == null
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }
      this.loading = true;
      this.disabled.submit = true;

      let data = {
        invoice_no: this.save.invoice_no,
        refundable: this.save.refundable,
        invoice_date: this.save.invoice_date,
        file: this.save.storage_file_name,
        file_name: this.save.file_name,
        invoice_total_amount: this.save.invoice_total_amount
      };
      let purchase_overview_id_arr = [];
      this.save.purchase_overview_id.forEach((element) => {
        purchase_overview_id_arr.push(element.value);
      });
      data.purchase_overview_id = purchase_overview_id_arr;
      if (this.id) {
        data.id = this.id;
        this.editPurchaseInvoice(data);
      } else {
        this.addPurchaseInvoice(data);
      }
    },
    addPurchaseInvoice(data) {
      let that = this;
      apiBus.uploadInvoice
        .addUploadPurchaseInvoice(data)
        .then((data) => {
          console.log(data);
          that.showModal.information = false;
          that.loading = false;
          that.disabled.submit = false;
          that.initFn();
          that.save = that.saveDefault;
          that.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          that.loading = false;
          that.disabled.submit = false;
          that.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    editPurchaseInvoice(data) {
      let that = this;
      apiBus.uploadInvoice
        .saveUploadPurchaseInvoiceDetail(data)
        .then((data) => {
          console.log(data);
          that.showModal.information = false;
          that.loading = false;
          that.disabled.submit = false;
          that.initFn();
          that.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          that.loading = false;
          that.disabled.submit = false;
          that.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    deleteModal() {
      this.showModal.delete = true;
    },
    confirm() {
      this.disabled.confirm = true;
      this.loading2 = true;
      apiBus.uploadInvoice
        .deletePurchaseInvoiceFile({ id: this.id })
        .then((data) => {
          console.log(data);
          this.showModal.delete = false;
          this.disabled.confirm = false;
          this.loading2 = false;

          this.initFn();
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Successfully Deleted!' // 提示内容
          });
        })
        .catch((error) => {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    init() {}
  },
  watch: {},
  computed: {
    ...mapState({
      baseConfig: (state) => state.baseconfig.baseconfig
    })
  },
  mounted() {
    this.init();
  }
};
</script>
<style scoped>
.image-box .bv-no-focus-ring {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image {
  max-width: 100%;
  max-height: 170px;
}
.upload-check {
  padding: 4px 10px !important;
  line-height: 13px !important;
  font-size: 13px;
  color: #666 !important;
  background-color: #e1e5ec;
  border-color: #e1e5ec;
  margin-bottom: 0 !important;
  border-left: 4px solid #00a0e9 !important;
  background-color: #e1e5ec;
}
.upload-delete {
  padding: 4px 10px !important;
  line-height: 13px !important;
  font-size: 13px;
  color: #666 !important;
  background-color: #e1e5ec;
  border-color: #e1e5ec;
  margin-bottom: 0 !important;
  border-left: 4px solid #df201f !important;
  background-color: #e1e5ec;
}
.upload-delete:not(.disabled):hover,
.upload-check:not(.disabled):hover,
.upload-delete:not(.disabled):active,
.upload-check:not(.disabled):active {
  color: #666 !important;
  background-color: #c2cad8 !important;
  border-top-color: #c2cad8 !important;
  border-bottom-color: #c2cad8 !important;
  border-right-color: #c2cad8 !important;
}
.action {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.action-tools {
  display: flex;
}
.action .file_name {
  flex: 1;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.action-btn.van-uploader >>> .van-uploader__input {
  font-size: 0;
}
</style>
